import { ElevateDateTime } from '@/common/date-time-helpers';
import { InterviewerView, Nullable, OptionalString, PromoterView } from '@/common/models';
import { EventStatus, EventType, ModelLoopEventFilterInput } from '@/graphql/types';

export const QueryKeys = {
  orgList: {
    all: ['orgList'],
    default: ['orgList', 'default'],
    simple: ['orgList', 'simple'],
  },
  org: (orgId: OptionalString) => ({
    all: [orgId],
    details: {
      all: [orgId, 'details'],
      general: [orgId, 'details', 'general'],
      roleLDAPGroups: {
        all: [orgId, 'details', 'roleLDAPGroups'],
        withFilter: (filter: OptionalString) => [orgId, 'details', 'roleLDAPGroups', filter],
      },
      trainings: [orgId, 'details', 'trainings'],
      skills: [orgId, 'details', 'skills'],
      phoneScreenConfigs: [orgId, 'details', 'phoneScreenConfigs'],
      collaborations: [orgId, 'details', 'collaborations'],
      defaultCalibrationShepherdData: [orgId, 'details', 'defaultCalibrationShepherdData'],
    },
    eventTags: [orgId, 'eventTags'],
    eventConfig: {
      all: [orgId, 'eventConfig'],
      type: (eventType: EventType) => ({
        all: [orgId, 'eventConfig', eventType],
        active: [orgId, 'eventConfig', eventType, 'active'],
      }),
      id: (configId: OptionalString) => [orgId, 'eventConfig', configId],
    },
    participant: {
      all: [orgId, 'participant'],
      type: (eventType: EventType) => ({
        all: [orgId, 'participant', eventType],
        active: [orgId, 'participant', eventType, 'active'],
      }),
      id: (participantId: OptionalString) => [orgId, 'participant', participantId],
    },
    calibration: {
      all: [orgId, 'calibration'],
      type: (eventType: EventType) => ({
        all: [orgId, 'calibration', eventType],
        active: [orgId, 'calibration', eventType, 'active'],
      }),
      id: (calibrationId: OptionalString) => [orgId, 'calibration', calibrationId],
    },
    collaboration: {
      all: [orgId, 'collaboration'],
      inbound: [orgId, 'collaboration', 'inbound'],
      outbound: [orgId, 'collaboration', 'outbound'],
    },
    profile: {
      all: [orgId, 'profile'],
      id: (profileId: OptionalString) => ({
        all: [orgId, 'profile', profileId],
        calibrations: [orgId, 'profile', profileId, 'calibrations'],
        availability: [orgId, 'profile', 'all', profileId, 'availability'],
        unavailability: [orgId, 'profile', 'all', profileId, 'unavailability'],
        skills: [orgId, 'profile', 'all', profileId, 'skills'],
        phoneScreen: [orgId, 'profile', 'all', profileId, 'phoneScreen'],
        loops: [orgId, 'profile', 'all', profileId, 'phoneScreen'],
        history: [orgId, 'profile', 'all', profileId, 'history'],
      }),
    },
    promoters: {
      all: [orgId, 'promoters'],
      mode: (mode: PromoterView) => ({
        all: [orgId, 'promoters', mode],
      }),
    },
    interviewers: {
      all: [orgId, 'interviewers'],
      mode: (mode: InterviewerView) => ({
        all: [orgId, 'interviewers', mode],
        idOrAlias: (value: string | null | undefined) => [orgId, 'interviewers', mode, value],
      }),
    },
    ivProfile: {
      all: [orgId, 'ivProfile'],
      calibration: (id: OptionalString) => ({
        all: [orgId, 'ivProfile', 'calibration', id],
        withFilter: (filter: OptionalString) => [orgId, 'ivProfile', 'calibration', id, filter],
      }),
    },
    roleProfile: {
      all: [orgId, 'roleProfile'],
      roles: (roleNames: string[]) => ({
        include: [orgId, 'roleProfile', roleNames, 'include'],
        exclude: [orgId, 'roleProfile', roleNames, 'exclude'],
      }),
    },
    loop: {
      all: ['loop', orgId],
      withFilters: (status: Nullable<EventStatus>, attrFilters: Nullable<ModelLoopEventFilterInput>) => [
        'loop',
        orgId,
        status,
        attrFilters,
      ],
      id: (id: OptionalString) => ['loop', orgId, id],
      history: (id: OptionalString) => ['loo', orgId, id, 'history'],
      recommendations: (id: OptionalString) => ['loop', orgId, id, 'recommendations'],
      comments: (id: OptionalString) => ['loop', orgId, id, 'comment'],
    },
    loopTemplate: {
      all: [orgId, 'loopTemplate'],
      id: (id: OptionalString) => [orgId, 'loopTemplate', id],
    },
    phoneScreen: {
      all: [orgId, 'phoneScreen'],
      withFilters: (status: Nullable<EventStatus>, afterDate: Nullable<ElevateDateTime>) => [
        orgId,
        'phoneScreen',
        status,
        afterDate,
      ],
      history: (id: OptionalString) => [orgId, 'phoneScreen', id, 'history'],
      recommendations: (id: OptionalString) => [orgId, 'phoneScreen', id, 'recommendations'],
      id: (id: OptionalString) => [orgId, 'phoneScreen', id],
      comments: (id: OptionalString) => [orgId, 'phoneScreen', id, 'comment'],
    },
    phoneScreenConfig: {
      all: [orgId, 'phoneScreenConfig'],
      maxDuration: [orgId, 'phoneScreenConfig', 'maxDuration'],
    },
    profileAvailability: {
      all: [orgId, 'profileAvailability'],
    },
    barRaiser: {
      all: [orgId, 'barRaiser'],
    },
    brit: {
      all: [orgId, 'brit'],
    },
    profilesWithShepherds: {
      all: [orgId, 'profilesWithShepherds'],
    },
    calibrationShepherd: {
      all: [orgId, 'calibrationShepherd'],
    },
  }),
  feedback: {
    all: ['feedback'],
    event: (eventType: EventType, id: OptionalString) => ({
      all: ['feedback', `${eventType}-${id}`],
      profile: (profileId: OptionalString) => ['feedback', `${eventType}-${id}`, profileId],
    }),
    profile: (profileId: OptionalString) => ({
      all: ['feedback', 'profile', profileId],
      type: (eventType: EventType) => ['feedback', 'profile', profileId, eventType],
    }),
    id: (feedbackId: OptionalString) => ['feedback', feedbackId],
  },
  calibration: {
    all: ['calibration'],
    id: (calibrationId: OptionalString) => ['calibration', calibrationId],
    cohortSize: (id: OptionalString) => ['calibration', id, 'cohortSize'],
  },
  event: {
    all: ['event'],
    id: (eventId: OptionalString) => ['event', eventId],
  },
  eventConfig: {
    all: ['eventConfig'],
    id: (eventConfigId: OptionalString) => ['eventConfig', eventConfigId],
  },
  eventParticipant: {
    all: ['eventParticipant'],
    id: (eventParticipantId: OptionalString) => ['eventParticipant', eventParticipantId],
  },
  eventConfigDefinition: {
    all: ['eventConfigDefinition'],
    id: (eventConfigDefinitionId: OptionalString) => ['eventConfigDefinition', eventConfigDefinitionId],
  },
  eventCalibration: {
    all: ['eventCalibration'],
  },
  profileAvailability: {
    all: ['profileAvailability'],
    id: (availabilityId: OptionalString) => ['profileAvailability', availabilityId],
  },
  comment: {
    all: ['comment'],
    id: (commentId: OptionalString) => ['comment', commentId],
  },
  interviewer: {
    all: ['interviewer'],
    id: (interviewerId: OptionalString) => ['interviewer', interviewerId],
  },
  eventInterview: {
    all: ['eventInterview'],
    routingAttempts: (id: OptionalString) => ['loop', id, 'routingAttempts'],
    languages: (id: OptionalString) => ['event', id, 'languages'],
  },
  loop: {
    all: ['loop'],
    profileId: (profileId: OptionalString) => ['loop', profileId],
  },
  phoneScreen: {
    all: ['phoneScreen'],
    profileId: (profileId: OptionalString) => ['phoneScreen', profileId],
  },
  knet: {
    all: ['knet'],
    employee: (employeeId: OptionalString) => ({
      all: ['knet', 'employee', employeeId],
      transcript: (trainingId: OptionalString) => ['knet', 'employee', employeeId, trainingId],
    }),
    id: (knetId: OptionalString) => ['knet', knetId],
  },
  peopleProfile: {
    all: ['peopleProfile'],
    user: (username: OptionalString) => ['peopleProfile', username],
  },
  profile: {
    all: ['profile'],
    alias: (alias: OptionalString) => ['profile', alias],
    id: (profileId: OptionalString) => ({
      all: ['profile', profileId],
      base: ['profile', profileId, 'base'],
      candidChatPreferences: ['profile', profileId, 'candidChatPreferences'],
      details: ['profile', profileId, 'details'],
      preferences: ['profile', profileId, 'preferences'],
      languages: ['profile', profileId, 'languages'],
    }),
    preferences: ['profile', 'preferences'],
  },
  cognito: ['cognito'],
  candidChatScreen: {
    all: ['candidChatScreen'],
    profile: (profileId: OptionalString) => ['candidChatScreen', 'profile', profileId],
    id: (id: OptionalString) => ['candidChatScreen', id],
  },
  questionBank: {
    all: ['questionbank'],
    team: (teamID: OptionalString) => ({
      all: ['questionbank', 'teams'],
      base: ['questionbank', 'teams', teamID],
      categories: ['questionbank', 'teams', teamID, 'categories'],
      jobRoles: ['questionbank', 'teams', teamID, 'jobRoles'],
      interviewerRoles: ['questionbank', 'teams', teamID, 'interviewerRoles'],
      questions: ['questionbank', 'teams', teamID, 'questions']
    }),
  },
};
